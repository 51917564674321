/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/site.css';


var $ = require('jquery');

window.$ = $;
window.jQuery = $;
global.$ = global.jQuery = $;

require('popper.js');
require('../appru/js/tilt.jquery.min');
require('bootstrap');

require('../appru/js/easing.min');
require('../appru/js/hoverIntent');
require('../appru/js/superfish.min');
require('../appru/js/jquery.ajaxchimp.min');
require('magnific-popup');
require('owl.carousel');
require('../appru/js/owl-carousel-thumb.min');
require('../appru/js/hexagons.min');
require('../appru/js/jquery.nice-select.min.js');
//require('../appru/js/waypoints.min');

require('../appru/js/main.js');